// HTML template for the EICard component
const templateHTML = `
<div class="icard-container">
  <div class="loading-placeholder">
    <div role="status" class="spinner-grow">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="details position-relative">
    <div class="icon">
      <i class="bi"></i>
    </div>
    <a href="#" class="link">
      <h3 class="title"></h3>
    </a>
    <p class="description"></p>
  </div>
</div>
`;

// Create a template element to store the card structure
const template = document.createElement("template");
template.id = "e-icard-template";
template.innerHTML = templateHTML;

export default class EICard extends HTMLElement {
  // Define static properties for template handling
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    // Initialize class properties
    this.card = null;
    this.loadingPlaceholder = null;
  }

  // Called when the element is added to the DOM
  connectedCallback() {
    // Load the template if it's not already loaded
    if (!EICard.templateLoaded) {
      EICard.template = template;
      EICard.templateLoaded = true;
    }

    this._loadTemplate();
    this._setAttributes();
    this._dataset();

    // Remove the loading placeholder once content is loaded
    if (this.loadingPlaceholder) {
      this.loadingPlaceholder.remove();
    }
  }

  // Method to load the template into the element
  _loadTemplate() {
    const template = EICard.template;

    if (template) {
      // Clone the template content and attach it to the custom element
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Store references to key elements within the template
      this.card = this.querySelector(".icard-container");
      this.loadingPlaceholder = this.querySelector(".loading-placeholder");
    } else {
      console.error("Template not found!"); // Error handling if the template is missing
    }
  }

  // Method to set attributes based on the element's attributes
  _setAttributes() {
    // Ensure the card container is uniquely identifiable
    this.card.setAttribute(
      "role",
      "icard-container-" + this._generateUniqueId()
    );

    // Apply any additional classes to the card container
    const classesToAdd = this.getAttribute("cardClass");
    if (classesToAdd) {
      this.card.classList.add(...classesToAdd.split(" "));
    }

    // Set attributes related to accessibility and identification
    this.loadingPlaceholder.setAttribute(
      "role",
      "icard-placeholder-" + this._generateUniqueId()
    );

    this.card.title =
      this.getAttribute("title") || "Card description not available";

    const ariaLabel = this.getAttribute("aria-label") || this.card.title;
    this.card.setAttribute("aria-label", ariaLabel);

    this.card.id =
      this.getAttribute("id") || "e-icard-" + this._generateUniqueId();
    this.card.setAttribute("role", "icard-" + this._generateUniqueId());
    this.card.setAttribute("tabindex", "0"); // Make the card focusable
  }

  // Helper method to generate a unique identifier
  _generateUniqueId() {
    return Math.floor(Math.random() * 100000);
  }

  _dataset() {
    // Set the icon class
    const i = this.card.querySelector("i");
    i.classList.add(this.getAttribute("icon") || "bi-exclamation-triangle");

    // Configure the link and its classes
    const link = this.card.querySelector(".link");
    link.href = this.getAttribute("link") || "#";
    link.classList.add(this.getAttribute("linkClass") || "");

    // Set the title text
    const title = this.card.querySelector(".title");
    title.textContent = this.getAttribute("title");

    // Set the description text
    const description = this.card.querySelector(".description");
    description.textContent = this.getAttribute("description");
  }
}
