// Define the HTML template for the custom element.
const templateHTML = `
<div class="image-container m-0 m-auto">
    <div class="loading-placeholder">
        <div role="status" class="spinner-grow">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <img class="lazy-load img-fluid" />
</div>
`;

// Create the template only if it hasn't been created yet
const template = document.createElement("template");
template.id = "e-image-template";
template.innerHTML = templateHTML;

export default class EImage extends HTMLElement {
  static templateLoaded = false; // Flag to check if the template has been loaded globally

  constructor() {
    super();
    this.img = null; // Will hold the reference to the image element
    this.loadingPlaceholder = null; // Will hold the reference to the loading placeholder
  }

  // Called when the element is added to the DOM
  connectedCallback() {
    if (!EImage.templateLoaded) {
      EImage.template = template;
      EImage.templateLoaded = true; // Set the flag to true
    }

    this._loadTemplate(); // Load and append the template to the element
  }

  // Method to load the template and attach it to the custom element
  _loadTemplate() {
    const template = EImage.template;

    if (template) {
      // Clone the template's content and append it to the element
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      // Get references to the image and loading placeholder elements
      this.img = this.querySelector("img");
      this.loadingPlaceholder = this.querySelector(".loading-placeholder");

      // Set attributes and initialize lazy loading
      this._setAttributes();
      this._initIntersectionObserver();
    } else {
      console.error("Template not found!"); // Log an error if the template is missing
    }
  }

  // Method to set attributes and styles based on the element's attributes
  _setAttributes() {
    const width = this.getAttribute("width"); // Get the width attribute (if any)
    const height = this.getAttribute("height"); // Get the height attribute (if any)

    // Set the width and height of the loading placeholder
    if (width) this.loadingPlaceholder.style.width = `${width}px`;
    this.loadingPlaceholder.style.height = height > 0 ? `${height}px` : "100%";
    this.loadingPlaceholder.setAttribute(
      "role",
      "img-placeholder-" + Math.floor(Math.random() * 100000) // Set a unique role for accessibility
    );

    // Set the data-src attribute for lazy loading and alt text
    this.img.dataset.src = this.getAttribute("src");
    this.img.alt =
      this.getAttribute("alt") || "Image description not available";

    // Add any additional classes provided via the class attribute
    const classesToAdd = this.getAttribute("class");
    if (classesToAdd) {
      this.img.classList.add(...classesToAdd.split(" "));
    }

    // Set aria-label and other accessibility attributes
    const ariaLabel = this.getAttribute("aria-label") || this.img.alt;
    this.img.setAttribute("aria-label", ariaLabel);

    if (this.hasAttribute("title")) {
      this.img.title = this.getAttribute("title");
    }

    // Set a unique ID and role for the image
    this.img.id =
      this.getAttribute("id") ||
      "e-image-" + Math.floor(Math.random() * 100000);
    this.img.setAttribute("role", "img-" + Math.floor(Math.random() * 100000));
    this.img.setAttribute("tabindex", "0"); // Make the image focusable

    // Style the image container to ensure the image covers the container without stretching
    const container = this.querySelector(".image-container");
    container.setAttribute(
      "role",
      "img-container-" + Math.floor(Math.random() * 100000) // Set a unique role for the container
    );
    container.style.position = "relative";
    container.style.overflow = "hidden";

    // Set the container and image dimensions based on the provided width and height
    if (width && height) {
      container.style.width = `${width}px`;
      container.style.height = `${height}px`;
      this.img.style.width = "100%";
      this.img.style.height = "100%";
    } else if (width) {
      container.style.width = `${width}px`;
      container.style.height = "auto";
      this.img.style.width = "100%";
      this.img.style.height = "auto";
    } else if (height) {
      container.style.height = `${height}px`;
      container.style.width = "auto";
      this.img.style.width = "auto";
      this.img.style.height = "100%";
    } else {
      container.style.width = "100%";
      container.style.height = "auto";
      this.img.style.width = "100%";
      this.img.style.height = "auto";
    }

    this.img.style.objectFit = "cover"; // Ensure the image covers the container proportionally
    this.img.width = width; // Set the width attribute on the image
    this.img.height = height; // Set the height attribute on the image
  }

  // Method to initialize IntersectionObserver for lazy loading
  _initIntersectionObserver() {
    if (!this.img) {
      console.error("imgElement is not defined or found in the DOM."); // Log an error if the image element is missing
      return;
    }

    // Check if the browser supports IntersectionObserver
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this._loadImage(); // Load the image when it comes into view
            observer.unobserve(this.img); // Stop observing once the image is loaded
          }
        });
      });

      observer.observe(this.img); // Start observing the image element
    } else {
      // Fallback for browsers that do not support IntersectionObserver
      this._loadImage();
    }
  }

  // Method to actually load the image
  _loadImage() {
    this.img.src = this.img.dataset.src; // Set the image source to the data-src attribute

    // Handle the image load event
    this.img.onload = () => {
      this.img.class = this.img.dataset.class; // Apply any classes after loading
      // Remove the loading placeholder once all attributes are set
      this.loadingPlaceholder.remove();
      //   this.loadingPlaceholder.style.display = "none"; // Hide the loading placeholder
      this.img.loading = "lazy"; // Enable native lazy loading (optional but good practice)
    };

    // Handle the image error event
    this.img.onerror = () => {
      console.error(`Failed to load image ${this.img.src}`); // Log an error if the image fails to load

      // Display a fallback message in the loading placeholder
      this.loadingPlaceholder.innerHTML = `Failed to load image ${this.img.src}`;
      this.img.style.display = "none"; // Hide the image if it fails to load
    };
  }
}
