document.addEventListener("DOMContentLoaded", () => {
  "use strict";
  // Constants

  // initializeImages & resizeImages

  /**
   * Lazy load images using IntersectionObserver
   */

  initializeImages();
  resizeImages();

  // Get all elements with the class 'jsCallback'
  const customDivs = document.querySelectorAll(".jsCallback");

  customDivs.forEach((div) => {
    // Extract the function name from the 'data-function' attribute
    const args = div.getAttribute("data-function");

    // Split the string into an array of words
    const parts = args.split(", ");

    // The first part is the function name
    const functionName = parts[0];

    // The remaining parts are the arguments
    const functionArgs = parts.slice(1) || null;

    if (functionName && typeof window[functionName] === "function") {
      // Call the specified function if it exists
      window[functionName](...functionArgs);
    } else {
      console.error(
        `Function ${functionName} is not defined or is not a valid function.`
      );
    }
  });

  // Set the default theme to dark if no setting is found in local storage
  const htmlElement = document.documentElement;
  const currentTheme = localStorage.getItem("bsTheme") || "dark";

  htmlElement.setAttribute("data-bs-theme", currentTheme);
  document.body.classList.add(currentTheme + "-mode");

  const switchElement = document.getElementById("darkModeSwitch");
  if (switchElement) {
    switchElement.checked = currentTheme === "dark";

    switchElement.addEventListener("change", () => {
      if (switchElement.checked) {
        htmlElement.setAttribute("data-bs-theme", "dark");
        document.body.classList.add("dark-mode");
        document.body.classList.remove("light-mode");
        localStorage.setItem("bsTheme", "dark");
      } else {
        document.body.classList.add("light-mode");
        document.body.classList.remove("dark-mode");
        htmlElement.setAttribute("data-bs-theme", "light");
        localStorage.setItem("bsTheme", "light");
      }
    });
  }

  /**
   * Apply .scrolled class to the body as the page is scrolled down
   */
  function toggleScrolled() {
    const selectBody = document.querySelector("body");
    // const selectHeader = document.querySelector("#header");
    // if (
    //   !selectHeader.classList.contains("scroll-up-sticky") &&
    //   !selectHeader.classList.contains("sticky-top") &&
    //   !selectHeader.classList.contains("fixed-top")
    // )
    //   return;
    window.scrollY > 100
      ? selectBody.classList.add("scrolled")
      : selectBody.classList.remove("scrolled");
  }

  document.addEventListener("scroll", toggleScrolled);
  window.addEventListener("load", toggleScrolled);

  /**
   * Scroll top button
   */
  let scrollTop = document.querySelector(".scroll-top");
  if (scrollTop) {
    function toggleScrollTop() {
      if (scrollTop) {
        if (window.scrollY > 100) {
          scrollTop.classList.add("active");
          scrollTop.classList.remove("d-none");
        } else {
          scrollTop.classList.remove("active");
          scrollTop.classList.add("d-none");
        }
      }
    }
    scrollTop.addEventListener("click", (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    window.addEventListener("load", toggleScrollTop);
    document.addEventListener("scroll", toggleScrollTop);
  }

  /**
   * Correct scrolling position upon page load for URLs containing hash links.
   */
  window.addEventListener("load", function (e) {
    if (window.location.hash) {
      if (document.querySelector(window.location.hash)) {
        setTimeout(() => {
          let section = document.querySelector(window.location.hash);
          let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
          window.scrollTo({
            top: section.offsetTop - parseInt(scrollMarginTop),
            behavior: "smooth",
          });
        }, 100);
      }
    }
  });

  /**
   * Preloader
   */
  const preloader = document.querySelector("#preloader");
  if (preloader) {
    window.addEventListener("load", () => {
      setTimeout(() => {
        document.getElementById("app").style.display = "block";
        preloader.remove();
      }, 500);
    });
  }
});

(() => {
  // Default page width
  const defaultPageWidth = 1000;

  // Function to resize images with the class 'autoresize'
  window.resizeImages = () => {
    const currentPageWidth = document.documentElement.clientWidth;
    const scaleFactor = currentPageWidth / defaultPageWidth;

    // Select all images with the class 'autoresize'
    const images = document.querySelectorAll("img.autoresize");

    images.forEach((image) => {
      const originalWidth = parseInt(
        image.getAttribute("data-original-width"),
        10
      );
      if (isNaN(originalWidth)) {
        // Store the original width in a data attribute if not already stored
        image.setAttribute("data-original-width", image.width);
      }

      // Set the new width based on the scale factor
      const newWidth = originalWidth * scaleFactor;
      image.style.width = `${newWidth}px`;

      // The height adjusts automatically based on the width since we're only setting the width
    });
  };

  // Initialize images by storing their original widths
  window.initializeImages = () => {
    const images = document.querySelectorAll("img.autoresize");
    images.forEach((image) => {
      if (!image.hasAttribute("data-original-width")) {
        image.setAttribute("data-original-width", image.width);
      }
    });
  };

  // Add event listener to window resize to trigger the resizing function
  window.addEventListener("resize", resizeImages);
})();
