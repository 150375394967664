const filePreviewTemplateHTML = `
  <div class="file-preview-container d-flex align-items-start">
  
    <!-- Folder List -->
    <div id="folderList" class="folder-list nav flex-column nav-pills me-3"></div>

    <!-- File List -->
    <div class="tab-content">
      <div class="file-list">
        <div id="fileGrid"></div>
      </div>
    </div>
  </div>
  <div id="pagination" class="d-flex justify-content-end"></div>

  <style>
    .file-item { position: relative; padding: 10px; cursor: pointer; }
    .file-item img, .file-item i { width: 50px; height: 50px; }
    .file-item .delete-icon { position: absolute; top: 5px; right: 5px; cursor: pointer; }
    .folder-item { margin-bottom: 10px; }
    .folder-item:hover { background-color: #f1f1f1; }
  </style>
`;

/**
 * @typedef {Object} Folder
 * @property {string} uid - The unique folder ID.
 * @property {string} name - The name of the folder.
 */

/**
 * @typedef {Object} File
 * @property {string} uid - The unique file ID.
 * @property {string} name - The file name.
 * @property {string} path - The file path.
 * @property {string} type - The file MIME type.
 */

/**
 * Class representing the file preview component.
 * @extends {HTMLElement}
 */
export default class FilePreview extends HTMLElement {
  constructor() {
    super();
    /** @type {Folder[]} */
    this.folderList = [];
    /** @type {File[]}  */
    this.fileList = [];
    /** @type {number|null} Current folder */
    this.currentFolder = null;
    /** @type {number} Default files per page */
    this.perPage = 10;
    /** @type {number} Current page */
    this.page = 1;
    /** @type {string} Default filter */
    this.filterType = "all";
  }

  connectedCallback() {
    if (!FilePreview.templateLoaded) {
      FilePreview.template = filePreviewTemplateHTML;
      FilePreview.templateLoaded = true;
    }

    this._loadTemplate();
    this._loadFolders();
  }

  _loadTemplate() {
    const template = FilePreview.template;
    if (template) {
      const templateContent = document.createElement("div");
      templateContent.innerHTML = template;
      this.appendChild(templateContent);

      this.folderListElement = this.querySelector("#folderList");
      this.fileGridElement = this.querySelector("#fileGrid");
      this.paginationElement = this.querySelector("#pagination");
      this.fileTypeFilterElement = this.querySelector("#fileTypeFilter");
    } else {
      console.error("Template not found!");
    }
  }

  async _loadFolders() {
    try {
      const response = await fetch(this.getAttribute("load"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": window.csrf,
        },
      });
      const data = await response.json();
      this.perPage = data.params.perPage;
      this._renderFolders(data.rows);
    } catch (error) {
      new Toast("Error loading folders: " + error, "danger");
    }
  }

  /**
   * Renders the folders and unrelated files.
   * @param {Object} row - The folder and file data.
   */
  _renderFolders(row) {
    this.folderListElement.innerHTML = "";

    const folderItem = this._createFolderItem({
      uid: null,
      name: "..",
    });
    this.folderListElement.appendChild(folderItem);

    for (const folderId in row.folders) {
      if (row.folders.hasOwnProperty(folderId)) {
        const folder = row.folders[folderId];
        const folderItem = this._createFolderItem(folder);
        this.folderListElement.appendChild(folderItem);
      }
    }

    this._renderUnrelatedFiles(row.files);
  }

  /**
   * Creates a folder item element.
   * @param {Folder} folder - The folder data.
   * @returns {HTMLElement}
   */
  _createFolderItem(folder) {
    const folderItem = document.createElement("div");
    folderItem.classList.add(
      "nav-link",
      "d-flex",
      "justify-content-between",
      "align-items-center"
    );
    folderItem.setAttribute("folderId", folder.uid);
    this._makeFolderDroppable(folderItem, folder.uid);

    const buttonIcon = document.createElement("button");
    buttonIcon.classList.add("btn", "py-2");
    buttonIcon.innerHTML = `<i class="bi bi-folder me-1"></i>${folder.name}`;

    buttonIcon.addEventListener("click", () => {
      // Remove available opacity from the current folder
      if (this.currentFolder) {
        const fileElement = this.querySelector(
          `[folderId="${this.currentFolder}"]`
        );
        fileElement.classList.remove("opacity-50");
      }
      // add available opacity to the clicked folder
      folderItem.classList.add("opacity-50");

      // Laod files
      this._loadFiles(folder.uid);
      this.currentFolder = folder.uid;
    });

    folderItem.appendChild(buttonIcon);

    if (folder.uid > 1) {
      const deleteIcon = document.createElement("i");

      deleteIcon.classList.add("btn", "bi", "bi-trash", "text-danger");

      deleteIcon.addEventListener("click", (e) => {
        e.stopPropagation();
        this._deleteFolder(folder.uid);
      });
      folderItem.appendChild(deleteIcon);
    }

    return folderItem;
  }

  async _loadFiles(folderId = null, page = 1) {
    try {
      let path =
        folderId == null
          ? this.getAttribute("load")
          : _setUid(this.getAttribute("folder"), { uid: folderId });

      const url = this._updateURLParams(path, {
        page: page,
        perPage: this.perPage,
        type: this.filterType,
      });

      const urlEncrypted = _hashUrl(url);
      const response = await fetch(urlEncrypted, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": window.csrf,
        },
      });
      const data = await response.json();
      this.perPage = data.params.perPage;
      this._renderFiles(data.rows.files.data, data.rows.files);
    } catch (error) {
      new Toast("Error loading files: " + error, "danger");
    }
  }

  _renderFiles(files, pagination) {
    this.fileGridElement.innerHTML = "";

    for (const file of files) {
      const fileItem = this._createFileItem(file);
      this.fileGridElement.appendChild(fileItem);
    }

    this._renderPagination(this.paginationElement, pagination, (page) => {
      this.page = page;
      this._loadFiles(this.currentFolder, page);
    });
  }

  _renderUnrelatedFiles(files) {
    this.fileGridElement.innerHTML = "";

    files.data.forEach((file) => {
      const fileItem = this._createFileItem(file);
      this.fileGridElement.appendChild(fileItem);
    });

    this._renderPagination(this.paginationElement, files, (page) => {
      this.page = page;
      this._loadFiles(null, page);
    });
  }

  _createFileItem(file) {
    const fileItem = document.createElement("div");
    fileItem.classList.add("position-relative", "text-center");
    fileItem.setAttribute("fileId", file.uid);

    const item = document.createElement("div");
    item.classList.add("btn", "p-2", "text-center");
    item.draggable = true;

    const previewElement = this._getFilePreviewElement(file);
    item.appendChild(previewElement);

    const fileName = document.createElement("p");
    fileName.textContent = file.name;
    item.appendChild(fileName);

    item.addEventListener("dragstart", (e) => {
      e.dataTransfer.setData("fileId", file.uid);
    });

    // Add Delete Icon
    const deleteIcon = document.createElement("i");
    deleteIcon.classList.add(
      "position-absolute",
      "top-0",
      "end-0",
      "bi",
      "bi-trash",
      "text-danger"
    );
    deleteIcon.addEventListener("click", () => this._deleteFile(file.uid));
    item.appendChild(deleteIcon);

    fileItem.appendChild(item);

    fileItem.appendChild(item);
    return fileItem;
  }

  _getFilePreviewElement(file) {
    let element;

    if (file.type.startsWith("image/")) {
      element = document.createElement("img");
      element.src = `${file.path}?w=50&h=50&crop=1`;
      element.width = "150";
      element.height = "150";
      element.classList.add("img-fluid", "rounded");
    } else {
      element = document.createElement("a");
      element.href = file.path;
      element.target = "_blank"; // Opens the link in a new tab
      element.classList.add("text-decoration-none");

      const icon = document.createElement("i");
      icon.classList.add("bi", "fs-2", this._getFileIconClass(file));
      element.appendChild(icon);
    }

    return element;
  }

  _getFileIconClass(fileType) {
    const typeMap = {
      pdf: "bi-filetype-pdf",
      text: "bi-filetype-text",
      txt: "bi-filetype-text",
      xml: "bi-filetype-xml",
      html: "bi-filetype-html",
      zip: "bi-file-zip",
      rar: "bi-file-zip",
      json: "bi-file-json",
      sql: "bi-file-sql",
      csv: "bi-file-csv",
      drawio: "bi-easel",
    };

    for (const type in typeMap) {
      if (fileType.mime_type.includes(type)) {
        return typeMap[type];
      }
    }

    return "bi-file-earmark"; // Default icon
  }

  _renderPagination(paginationElement, pagination, onPageChange) {
    paginationElement.innerHTML = "";

    const ul = document.createElement("ul");
    ul.classList.add("pagination");

    for (let i = 1; i <= pagination.last_page; i++) {
      const li = document.createElement("li");
      li.classList.add("page-item");
      if (i === pagination.current_page) li.classList.add("active");

      const a = document.createElement("a");
      a.classList.add("page-link");
      a.textContent = i;
      a.href = "#";
      a.addEventListener("click", (e) => {
        e.preventDefault();
        onPageChange(i);
      });

      li.appendChild(a);
      ul.appendChild(li);
    }

    paginationElement.appendChild(ul);
  }

  /**
   * Deletes the folder with the given ID.
   * @param {string} folderId - The folder's unique ID.
   */
  async _deleteFolder(folderId) {
    if (confirm("Are you sure you want to delete this folder?")) {
      try {
        const response = await fetch(
          _setUid(this.getAttribute("folderDelete"), { uid: folderId }),
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": window.csrf,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          // Remove file from current folder in the UI
          const fileElement = this.querySelector(`[folderId="${folderId}"]`);
          if (fileElement) {
            fileElement.remove();
          }
        } else {
          new Toast("Error deleting folder: " + data.message, "danger");
        }
      } catch (error) {
        new Toast("Error deleting folder: " + error, "danger");
      }
    }
  }

  /**
   * Deletes the file with the given ID.
   * @param {string} fileId - The file's unique ID.
   * @returns {Promise<void>}
   */

  async _deleteFile(fileId) {
    if (confirm("Are you sure you want to delete this file?")) {
      try {
        const response = await fetch(
          _setUid(this.getAttribute("fileDelete"), { uid: fileId }),
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": window.csrf,
            },
          }
        );
        if (response.ok) {
          // Remove the file from the UI
          const fileElement = this.querySelector(`[fileId="${fileId}"]`);
          if (fileElement) {
            fileElement.remove();
          }
        }
      } catch (error) {
        new Toast("Error deleting file: " + error, "danger");
      }
    }
  }

  _updateURLParams(url, params) {
    const urlObj = new URL(url, window.location.origin);
    Object.keys(params).forEach((key) =>
      urlObj.searchParams.append(key, params[key])
    );
    return urlObj.href;
  }

  _hashUrl(url) {
    return btoa(url); // Simple Base64 encoding as an example.
  }

  _makeFolderDroppable(element, folderId) {
    element.addEventListener("dragover", (e) => {
      e.preventDefault();
      element.style.backgroundColor = "#f1f1f1";
    });

    element.addEventListener("dragleave", () => {
      element.style.backgroundColor = "";
    });

    element.addEventListener("drop", (e) => {
      e.preventDefault();
      element.style.backgroundColor = "";

      const fileId = e.dataTransfer.getData("fileId");
      if (fileId) {
        this._moveFile(fileId, folderId);
      }
    });
  }

  async _moveFile(fileId, folderId) {
    try {
      const response = await fetch(
        _setUid(this.getAttribute("fileMove"), {
          file: fileId,
          folder: folderId,
        }),
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": window.csrf,
          },
          body: JSON.stringify({ folderId }),
        }
      );

      const data = await response.json();
      if (data.success) {
        const fileElement = this.querySelector(`[fileId="${fileId}"]`);
        if (fileElement) {
          fileElement.remove();
        }
      } else {
        new Toast("Error moving file: " + data.message, "danger");
      }
    } catch (error) {
      new Toast("Error moving file: " + error, "danger");
    }
  }
}
