// JS
import "./js/global.js";
import "./js/bootstrap/bootstrap.bundle.min.js";
import "./js/main.js";
import "./js/components/index.js";

window.test = (data) => {
  return new Toast("TODO Default Message!", "success");
};

window.close_policy = (data) => {
  const backdrop = document.querySelector("#policy");
  const offcanvas = document.querySelector(".offcanvas-backdrop");

  if (backdrop && offcanvas) {
    backdrop.remove();
    offcanvas.remove();
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";

    return new Toast(
      "Thank you! You have accepted our privacy policy.",
      "success"
    );
  } else {
    return new Toast("TODO Default Message!", "danger");
  }
};

// CSS
import "./css/bootstrap-icons/bootstrap-icons.min.css";

// SCSS
import "./scss/index.scss";
