import EICard from "./icard.js";
customElements.define("e-icard", EICard);

import { EToast } from "./toast.js";
customElements.define("e-toast", EToast);

// Define the custom element
import EImage from "./image.js";
customElements.define("e-image", EImage);

import SubmitButton from "./submit-button.js";
customElements.define("e-submit-button", SubmitButton);

import OverviewTable from "./table/overview.js";
customElements.define("overview-table", OverviewTable);

import EEach from "./each.js";
// Register the custom element with the browser
customElements.define("e-each", EEach);

import FileUpload from "./upload.js";
customElements.define("file-upload", FileUpload);

import FilePreview from "./filepreview.js";
customElements.define("file-preview", FilePreview);
