// Define the HTML template for the file upload element
const fileUploadTemplateHTML = `
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  <i class="bi bi-cloud-arrow-up"></i>
  Upload Files
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    
      <form id="fileUploadForm" class="modal-content" enctype="multipart/form-data">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Upload Files</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
 
        <div id="dropArea" class="drop-area d-flex align-items-center justify-content-center">
          <div class="text-center">
            <i class="bi bi-cloud-arrow-up display-1"></i> <!-- Upload icon -->
            <p>Drag & drop files here or click to upload</p>
          </div>
        </div>

        <!-- Hidden file input -->
        <input type="file" id="fileInput" name="files" multiple class="d-none"> 

        <!-- Folder selection dropdown -->
        <div class="mt-3">
          <label for="folderSelect" class="form-label">Select Folder</label>
          <select id="folderSelect" class="form-select text-capitalize"></select>
          <div id="folderLoading" class="text-muted mt-1 d-none">Loading folders...</div>
        </div>

        <table id="previewContainer" class="table table-striped table-hover mt-3 d-none">
          <thead>
            <tr>
              <th scope="col">File Name</th>
              <th scope="col">Size (KB)</th>
              <th scope="col" class="text-end">Preview</th>
            </tr>
          </thead>
          <tbody></tbody>
          <caption id="caption"></caption>
        </table>

      </div>
      <div class="modal-footer">
        <button type="submit" id="uploadButton" class="btn btn-primary">
          <i class="bi bi-upload"></i>
        </button>
          <div id="uploadLoading" class="text-muted mt-1 d-none">Uploading files...</div>
      </div>
    </form>
  </div>
</div>




  <style>
  .drop-area {
  border: 2px dashed #cccccc;
  border-radius: 10px;
  padding: 30px 10px;
  cursor: pointer;
  position: relative;
}

.drop-area:hover {
  opacity: .5;
}

.drop-area.dragging {
  opacity: .5;
}

.drop-area i {
  font-size: 4rem;
  color: #007bff;
}

.drop-area p {
  font-size: 1.2rem;
  color: #6c757d;
}

</style>
`;

const fileUploadTemplate = document.createElement("template");
fileUploadTemplate.id = "file-upload-template";
fileUploadTemplate.innerHTML = fileUploadTemplateHTML;

export default class FileUpload extends HTMLElement {
  static templateLoaded = false;
  static template = null;

  constructor() {
    super();
    this.fileList = [];
    this.fileInput = null;
    this.previewContainer = null;
    this.uploadButton = null;
    this.folderSelect = null;
    this.form = null;
    this.dropArea = null;
  }

  connectedCallback() {
    if (!FileUpload.templateLoaded) {
      FileUpload.template = fileUploadTemplate;
      FileUpload.templateLoaded = true;
    }
    this._loadTemplate();
    this._fetchFolders(); // Fetch folder list on load
  }

  _loadTemplate() {
    const template = FileUpload.template;

    if (template) {
      const templateContent = template.content.cloneNode(true);
      this.appendChild(templateContent);

      this.fileInput = this.querySelector("#fileInput");
      this.previewContainer = this.querySelector("#previewContainer");
      this.folderSelect = this.querySelector("#folderSelect");
      this.uploadButton = this.querySelector("#uploadButton");
      this.form = this.querySelector("#fileUploadForm");
      this.dropArea = this.querySelector("#dropArea");

      // Bind event handlers
      this.handleFileChange = this.handleFileChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDragOver = this.handleDragOver.bind(this);
      this.handleDrop = this.handleDrop.bind(this);
      this.triggerFileInput = this.triggerFileInput.bind(this);

      this.fileInput.addEventListener("change", this.handleFileChange);
      this.form.addEventListener("submit", this.handleSubmit);
      this.dropArea.addEventListener("dragover", this.handleDragOver);
      this.dropArea.addEventListener("drop", this.handleDrop);
      this.dropArea.addEventListener("click", this.triggerFileInput);
    } else {
      console.error("Template not found!");
    }
  }

  // Fetch folders from the API and populate the folder select
  _fetchFolders() {
    const folderLoading = this.querySelector("#folderLoading");
    folderLoading.classList.remove("d-none");

    fetch(this.getAttribute("folder"), {
      method: "GET",
      headers: {
        "X-CSRF-Token": window.csrf || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.folderSelect.innerHTML =
          `<option>-</option>` +
          data.rows.data
            .map(
              (folder) =>
                `<option value="${folder.uid}">${folder.name}</option>`
            )
            .join("");
        folderLoading.classList.add("d-none");
      })
      .catch((error) => {
        new Toast(`Error fetching folders: ${error}`, "danger");
        folderLoading.textContent = "Error loading folders.";
      });
  }

  triggerFileInput() {
    // Trigger hidden file input click when drop area is clicked
    this.fileInput.click();
  }

  handleDragOver(event) {
    event.preventDefault(); // Prevent default behavior (open as link on drop)
    this.dropArea.classList.add("dragging");
  }

  handleDrop(event) {
    event.preventDefault();
    this.dropArea.classList.remove("dragging");

    const files = Array.from(event.dataTransfer.files); // Get the dropped files
    this.fileList = this.fileList.concat(files);
    this.renderPreview();
  }

  handleFileChange(event) {
    const files = Array.from(event.target.files);
    this.fileList = this.fileList.concat(files);
    this.renderPreview();
  }

  renderPreview() {
    const tbody = this.previewContainer.querySelector("tbody");
    const caption = this.previewContainer.querySelector("#caption");
    tbody.innerHTML = "";

    this.fileList.forEach((file, index) => {
      const fileRow = document.createElement("tr");

      const fileNameCell = document.createElement("td");
      const div = document.createElement("div");
      div.classList.add("input-group");

      // File rename input
      const fileNameInput = document.createElement("input");
      fileNameInput.type = "text";
      fileNameInput.value = file.name;
      fileNameInput.classList.add("form-control", "form-control-sm");
      fileNameInput.addEventListener("change", () => {
        if (file.name != fileNameInput.value) {
          // Extract the extension from the original filename
          const extension = file.name.split(".").pop();

          // Construct the new filename with the extension
          const newFilenameWithExtension =
            fileNameInput.value + "." + extension;

          this.fileList[index].newName = newFilenameWithExtension; // Save the new name
        } else {
          this.fileList[index].newName = fileNameInput.value; // Save the new name
        }
      });

      const removeBtn = document.createElement("button");
      removeBtn.type = "button";
      removeBtn.classList.add("btn", "text-danger");
      removeBtn.innerHTML = `<i class="bi bi-trash"></i>`;
      removeBtn.addEventListener("click", () => {
        this.fileList.splice(index, 1);
        this.renderPreview();
      });

      div.appendChild(removeBtn);
      div.appendChild(fileNameInput);
      fileNameCell.appendChild(div);

      const previewCell = document.createElement("td");
      previewCell.classList.add("text-end");

      if (file.type.startsWith("image/")) {
        const img = document.createElement("img");
        img.src = URL.createObjectURL(file);
        img.style.width = "50px";
        previewCell.appendChild(img);
      } else {
        const fileLink = document.createElement("a");
        fileLink.href = URL.createObjectURL(file);
        fileLink.target = "_blank";
        fileLink.innerHTML = '<i class="bi bi-link"></i>';
        previewCell.appendChild(fileLink);
      }

      const sizeCell = document.createElement("td");
      sizeCell.textContent = (file.size / 1024).toFixed(2);

      fileRow.appendChild(fileNameCell);
      fileRow.appendChild(sizeCell);
      fileRow.appendChild(previewCell);

      tbody.appendChild(fileRow);
    });

    if (this.fileList.length > 0) {
      caption.textContent = `${this.fileList.length} file${
        this.fileList.length === 1 ? "" : "s"
      } selected, totaling ${(
        this.fileList.reduce((total, file) => total + file.size, 0) / 1024
      ).toFixed(2)} KB.`;
      this.previewContainer.classList.remove("d-none");
    } else caption.textContent = "";
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.fileList.length < 1)
      return new Toast("Not Files selected!", "warning");
    this.uploadButton.disabled = true;

    const folderId = this.folderSelect.value;

    const uploadLoading = this.querySelector("#uploadLoading");
    uploadLoading.classList.remove("d-none");

    const formData = new FormData();
    formData.append("folder_id", folderId);

    this.fileList.forEach((file, index) => {
      const fileToUpload = file.newName ? new File([file], file.newName) : file;
      formData.append("files[]", fileToUpload);
    });

    fetch(this.getAttribute("upload"), {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": window.csrf || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        new Toast(data.message, "success");
        this.fileList = [];
        this.fileInput.value = "";
        this.renderPreview();
        uploadLoading.classList.add("d-none");
        this.uploadButton.disabled = false;
      })
      .catch((error) => {
        new Toast(`Upload Error: ${error}`, "danger");
        this.uploadButton.disabled = false;

        uploadLoading.textContent = "Upload failed. Please try again.";
      });
  }
}
