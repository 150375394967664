export default class EEach extends HTMLElement {
  constructor() {
    super();
    this.structure;
  }

  // Called when the custom element is added to the DOM
  connectedCallback() {
    const arrayExpression = this.getAttribute("for");
    const data = this.parseData(this.getAttribute("data"));
    this.structure = this.getAttribute("structure");

    if (!arrayExpression || !this.structure || !data) {
      console.error(
        "The 'for' attribute, 'structure', or 'data' is missing or invalid."
      );
      return;
    }

    // Parse the 'for' expression like "item, index"
    const [itemName, indexName] = arrayExpression
      .split(",")
      .map((s) => s.trim());

    if (!Array.isArray(data)) {
      console.error(
        `Data array is not valid. Expected an array but got '${typeof data}'.`
      );
      return;
    }

    this.renderLoop(data, itemName, indexName);
  }

  // Helper function to parse data from string
  parseData(dataAttribute) {
    try {
      return JSON.parse(dataAttribute);
    } catch (error) {
      console.error("Invalid JSON data:", error);
      return null;
    }
  }

  // Render the loop with the provided data array
  renderLoop(data, itemName, indexName) {
    const container = document.createElement("div");

    data.forEach((item, index) => {
      const card = document.createElement("div");
      let content = this.structure;

      // Replace the index if present using the `index` syntax
      if (indexName) {
        content = content.replace(new RegExp(indexName, "g"), index);
      }

      // Replace item properties in the template using the `item.property` syntax
      if (typeof item === "object") {
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const value = ["object", "array"].includes(typeof item[key])
              ? JSON.stringify(item[key])
              : item[key];
            content = content.replace(
              new RegExp(`${itemName}\\.${key}`, "g"),
              value
            );
          }
        }
      } else {
        content = content.replace(new RegExp(itemName, "g"), item);
      }

      card.innerHTML = content;
      container.appendChild(card);
    });

    this.appendChild(container);
  }
}
