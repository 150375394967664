const templateHTML = `
    <div class="table-container">
  	 
    </div>
`;

const template = document.createElement("template");
template.id = "overview-table-template";
template.innerHTML = templateHTML;

/**
 * Custom Element: OverviewTable
 * - Manages overview table and language switching.
 */
export default class OverviewTable extends HTMLElement {
  static isTemplateLoaded = false;
  static template = null;

  constructor() {
    super();
    this.overview = {};
    this.displayNames = {};
  }

  connectedCallback() {
    if (!OverviewTable.isTemplateLoaded) {
      OverviewTable.template = template;
      OverviewTable.isTemplateLoaded = true;
    }
    this._loadTemplate();
    this._loadOverviews();
  }

  // Load and append the HTML template for the table
  _loadTemplate() {
    const templateContent = OverviewTable.template.content.cloneNode(true);
    this.appendChild(templateContent);

    const displayNames = this.getAttribute("displayNames") || false;
    if (displayNames) {
      this.displayNames = JSON.parse(
        displayNames.replace(/(\r\n|\n|\r|\t)/gm, "")
      ); // Parse JSON config
    }
  }

  // Fetch and display overview for the selected language
  async _loadOverviews() {
    const loadUrl = this.getAttribute("load");

    if (!loadUrl) {
      console.error("Load URL is missing!");
      return;
    }

    this._showLoadingState();

    try {
      const response = await fetch(`${loadUrl}`, {
        headers: {
          "X-CSRF-Token": window.csrf || "",
        },
      });
      const overview = await response.json();
      this.overview = overview;

      this._renderTable();
    } catch (error) {
      console.error("Error loading overview:", error);
    }
  }

  // Build the table of overview
  _renderTable() {
    this.innerHTML = ""; // Clear previous content
    const container = document.createElement("div");
    container.classList.add("row");

    Object.keys(this.displayNames).forEach((index) => {
      const row = this.overview[index] || false;

      if (row) {
        const box = document.createElement("div");
        box.classList.add("col-md-4", "px-1", "mb-3");

        const card = document.createElement("div");
        card.classList.add("card", "text-capitalize");

        const header = document.createElement("div");
        header.classList.add(
          "d-flex",
          "justify-content-between",
          "card-header"
        );

        header.innerHTML = `
        <strong>${this.displayNames[index].label}</strong>`;

        if (this.displayNames[index].link) {
          header.innerHTML += `
          <a href="${this.displayNames[index].link}">
            <i class="bi bi-box-arrow-up-right"></i>
          </a>`;
        }

        card.appendChild(header);

        const list = document.createElement("ul");
        list.classList.add("list-group", "list-group-flush");

        let counter = 0;
        row.map((e) => {
          const item = document.createElement("li");
          item.classList.add("list-group-item");

          Object.keys(e).forEach((key) => {
            const value = e[key];
            if (typeof key == "string") key = key.replaceAll("_", " ");

            if (key === "count") {
              counter += value;
              item.textContent += `(${value})`;
            } else {
              if (key === index) {
                item.textContent += `${value} `;
              } else {
                item.textContent += `${key}: ${value} `;
              }
            }
          });

          list.appendChild(item);
        });

        card.appendChild(list);

        if (counter > 0) {
          const footer = document.createElement("div");
          footer.classList.add("card-footer");
          footer.textContent = counter;
          card.appendChild(footer);
        }

        box.appendChild(card);
        container.appendChild(box);
      }
    });

    this.appendChild(container);
  }

  // Show loading placeholder while data is being fetched
  _showLoadingState() {
    this.innerHTML = `
            <div id="loading">
                <span class="placeholder col-6"></span>
                <span class="placeholder w-75"></span>
                <span class="placeholder" style="width: 25%;"></span>
            </div>
        `;
  }
}
